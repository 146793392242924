import * as React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import SEO from "../components/seo";

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background: #fbf7f3;
  z-index: 0;
  @media screen and (max-width: 411px) {
    max-height: 731px;
  }
  #BG {
    width: 2032px;
    height: auto !important;
    @media screen and (max-width: 411px) {
      width: 1950px;
    }
  }

  h1 {
    font-family: "Eames Century Modern";
    color: black;
    font-weight: normal;
    font-size: 80px;
    font-style: italic;
    z-index: 2;
    background: none;
    margin-bottom: 8px;
    @media screen and (max-width: 800px) {
      font-size: 60px;
    }
    @media screen and (max-width: 600px) {
      font-size: 40px;
    }
    @media screen and (max-width: 400px) {
      font-size: 30px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 64px 120px 56px 120px;
    z-index: 2;

    @media screen and (max-width: 900px) {
      padding: 64px 100px 56px 100px;
    }
    @media screen and (max-width: 830px) {
      padding: 64px 20px 56px 20px;
    }

    @media screen and (max-width: 685px) {
      padding: 64px 0px 56px 0px;
    }

    @media screen and (max-width: 630px) {
      padding: 30px 60px 20px 60px;
    }
    @media screen and (max-width: 400px) {
      padding: 30px 50px 20px 50px;
    }
  }

  button {
    align-self: center;
    font-family: "Open Sans", serif;
    background: #f37549;
    border: none;
    color: white;
    padding: 14px 32px;
    border: 2px solid #f37549;
    border-radius: 100px;
    margin-top: -40px;

    @media screen and (max-width: 630px) {
      margin-top: 0px;
    }
    :hover {
      background-color: white; /* Green */
      color: #f37549;
      cursor: pointer;
      transition: 0.2s;
      transform: translateY(-2px);
    }
  }

  input {
    border-style: none none solid none;
    font-family: "Open Sans", serif;
    border-color: #f37549;
    border-width: 3px;
    outline: none;
    width: 224px;
  }

  textarea {
    resize: vertical;
    font-family: "Open Sans", serif;
    border-style: none none solid none;
    border-color: #f37549;
    border-width: 3px;
    outline: none;
  }
`;

const LabelWrapper = styled.section`
  display: flex;
  flex-direction: column;

  font-family: "Open Sans", serif;
  color: #3c5a76;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 80px;
  margin-left: 40px;
  margin-right: 40px;

  p {
    margin-bottom: 8px;
  }

  @media screen and (max-width: 630px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
    font-size: 10px;
  }

  @media screen and (max-width: 400px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 20px;

    p {
      margin-bottom: 0px;
    }
  }
`;

const RowWrapper = styled.section`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 630px) {
    flex-direction: column;
  }
`;

//form no-redirect to netlify submission page
const onSubmit = async (event) => {
  event.preventDefault();

  const formElements = [...event.currentTarget.elements];
  const isValid =
    formElements.filter((elem) => elem.name === "bot-field")[0].value === "";

  const validFormElements = isValid ? formElements : [];

  if (validFormElements.length < 1) {
    // or some other cheeky error message
    toast.error("Invalid amount of inputs");
  } else {
    const filledOutElements = validFormElements
      .filter((elem) => !!elem.value)
      .map(
        (element) =>
          encodeURIComponent(element.name) +
          "=" +
          encodeURIComponent(element.value)
      )
      .join("&");

    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: filledOutElements,
    })
      .then(() => {
        toast.success("Successfully sent!");
        event.target.reset();
      })
      .catch((_) => {
        toast.error("Failed to send!");
      });
  }
};

function ContactUs() {
  const { SEOImg } = useStaticQuery(graphql`
    query {
      SEOImg: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout identifier="Contact Us" hideFooter="true" reverseNavColor={true}>
      <SEO title="Contact Us" image={SEOImg.childImageSharp.fluid} />
      <div>
        <Toaster position="top-right" reverseOrder={false} />
      </div>

      <HeroSection>
        <StaticImage
          src="../images/ContactUs/ContactUsBackground.png"
          placeholder="Background"
          style={{
            zIndex: "1",
            position: "absolute",
            height: "775px",
            maxHeight: "775px",
            left: "-540px",
            top: "200px",
            overflow: "hidden",
          }}
          quality={100}
          alt=""
          id="BG"
        ></StaticImage>
        <h1>WORK WITH US!</h1>
        <form
          name="Form Submissions"
          method="post"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit={(e) => onSubmit(e)}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Form Submissions" />
          <RowWrapper>
            <LabelWrapper>
              <p>NAME</p>
              <input type="text" name="Name" id="name"></input>
            </LabelWrapper>
            <LabelWrapper>
              <p>ORGANIZATION</p>
              <input type="text" name="Organization" id="organization"></input>
            </LabelWrapper>
          </RowWrapper>
          <RowWrapper>
            <LabelWrapper>
              <p>EMAIL</p>
              <input type="text" name="Email" id="email"></input>
            </LabelWrapper>
            <LabelWrapper>
              <p>PHONE NUMBER</p>
              <input type="text" name="Phone Number" id="phone-number"></input>
            </LabelWrapper>
          </RowWrapper>
          <LabelWrapper>
            <p>MESSAGE</p>
            <textarea type="text" name="Message" id="message"></textarea>
          </LabelWrapper>
          <button type="submit"> SUBMIT </button>
        </form>
      </HeroSection>
    </Layout>
  );
}

export default ContactUs;
